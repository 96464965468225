import React, { FunctionComponent } from "react";
import * as Styled from "./Button.styled";
import { ColorType } from "../../@types/ColorType";

interface ButtonLinkType {
  text: string;
  link: string;
  color?: ColorType;
  target?: string;
}

const ButtonLink: FunctionComponent<ButtonLinkType> = ({
  text,
  link,
  color = "lime",
  target,
}) => {
  return (
    <Styled.ButtonLink
      href={link}
      color={color}
      target={target}
      ariaLabel={text}
    >
      {text}
    </Styled.ButtonLink>
  );
};

export default ButtonLink;
