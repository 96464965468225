import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";

export const LinesDesktop = styled.svg`
  display: block;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      display: none;
    `
  )}
`;

export const LinesMobile = styled.svg`
  display: none;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      display: block;
    `
  )}
`;

const Lines: FunctionComponent = () => {
  return (
    <>
      <LinesDesktop
        width="616"
        height="16"
        viewBox="0 0 616 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_238_221)">
          <path
            d="M104.828 15.625L108.064 0.625H106.236L103 15.625H104.828Z"
            fill="#007395"
          />
          <path
            d="M90.4188 15.625L93.6549 0.625H91.8269L88.5908 15.625H90.4188Z"
            fill="#007395"
          />
          <path
            d="M95.2372 15.625L98.4732 0.625H96.6453L93.4092 15.625H95.2372Z"
            fill="#007395"
          />
          <path
            d="M100.01 15.625L103.246 0.625H101.418L98.1817 15.625H100.01Z"
            fill="#007395"
          />
          <path
            d="M109.959 15.625L113.195 0.625H111.372L108.136 15.625H109.959Z"
            fill="#007395"
          />
          <path
            d="M114.777 15.625L118.013 0.625H116.19L112.954 15.625H114.777Z"
            fill="#007395"
          />
          <path
            d="M119.396 15.625L122.632 0.625H120.804L117.568 15.625H119.396Z"
            fill="#007395"
          />
          <path
            d="M124.526 15.625L127.762 0.625H125.934L122.698 15.625H124.526Z"
            fill="#007395"
          />
          <path
            d="M129.344 15.625L132.581 0.625H130.753L127.522 15.625H129.344Z"
            fill="#007395"
          />
          <path
            d="M148.817 15.625L152.053 0.625H150.225L146.989 15.625H148.817Z"
            fill="#007395"
          />
          <path
            d="M134.408 15.625L137.644 0.625H135.816L132.58 15.625H134.408Z"
            fill="#007395"
          />
          <path
            d="M139.226 15.625L142.463 0.625H140.635L137.398 15.625H139.226Z"
            fill="#007395"
          />
          <path
            d="M143.999 15.625L147.235 0.625H145.407L142.171 15.625H143.999Z"
            fill="#007395"
          />
          <path
            d="M153.948 15.625L157.184 0.625H155.356L152.12 15.625H153.948Z"
            fill="#007395"
          />
          <path
            d="M158.766 15.625L162.002 0.625H160.174L156.943 15.625H158.766Z"
            fill="#007395"
          />
          <path
            d="M163.38 15.625L166.621 0.625H164.793L161.557 15.625H163.38Z"
            fill="#007395"
          />
          <path
            d="M168.515 15.625L171.751 0.625H169.924L166.687 15.625H168.515Z"
            fill="#007395"
          />
          <path
            d="M173.334 15.625L176.57 0.625H174.742L171.506 15.625H173.334Z"
            fill="#007395"
          />
          <path
            d="M16.7372 15.625L19.9732 0.625H18.1453L14.9092 15.625H16.7372Z"
            fill="#10384F"
          />
          <path
            d="M2.32798 15.625L5.56407 0.625H3.73609L0.5 15.625H2.32798Z"
            fill="#10384F"
          />
          <path
            d="M7.14628 15.625L10.3824 0.625H8.55439L5.3183 15.625H7.14628Z"
            fill="#10384F"
          />
          <path
            d="M11.9188 15.625L15.1549 0.625H13.3269L10.0908 15.625H11.9188Z"
            fill="#10384F"
          />
          <path
            d="M21.867 15.625L25.1031 0.625H23.2751L20.0391 15.625H21.867Z"
            fill="#10384F"
          />
          <path
            d="M26.6854 15.625L29.9215 0.625H28.0935L24.8574 15.625H26.6854Z"
            fill="#10384F"
          />
          <path
            d="M31.2994 15.625L34.5355 0.625H32.7127L29.4766 15.625H31.2994Z"
            fill="#10384F"
          />
          <path
            d="M36.4354 15.625L39.6715 0.625H37.8435L34.6074 15.625H36.4354Z"
            fill="#10384F"
          />
          <path
            d="M41.2528 15.625L44.4889 0.625H42.6609L39.4248 15.625H41.2528Z"
            fill="#10384F"
          />
          <path
            d="M60.7213 15.625L63.9574 0.625H62.1345L58.8984 15.625H60.7213Z"
            fill="#10384F"
          />
          <path
            d="M46.3121 15.625L49.5482 0.625H47.7254L44.4893 15.625H46.3121Z"
            fill="#10384F"
          />
          <path
            d="M51.1304 15.625L54.3665 0.625H52.5437L49.3076 15.625H51.1304Z"
            fill="#10384F"
          />
          <path
            d="M55.9029 15.625L59.139 0.625H57.3162L54.0801 15.625H55.9029Z"
            fill="#10384F"
          />
          <path
            d="M65.8563 15.625L69.0924 0.625H67.2644L64.0283 15.625H65.8563Z"
            fill="#10384F"
          />
          <path
            d="M70.6747 15.625L73.9107 0.625H72.0828L68.8467 15.625H70.6747Z"
            fill="#10384F"
          />
          <path
            d="M75.2884 15.625L78.5245 0.625H76.6965L73.4656 15.625H75.2884Z"
            fill="#10384F"
          />
          <path
            d="M80.4195 15.625L83.6556 0.625H81.8328L78.5967 15.625H80.4195Z"
            fill="#10384F"
          />
          <path
            d="M85.2369 15.625L88.4781 0.625H86.6502L83.4141 15.625H85.2369Z"
            fill="#10384F"
          />
          <path
            d="M192.56 15.625L195.796 0.625H193.969L190.732 15.625H192.56Z"
            fill="#00B2E6"
          />
          <path
            d="M178.152 15.625L181.388 0.625H179.56L176.324 15.625H178.152Z"
            fill="#00B2E6"
          />
          <path
            d="M182.971 15.625L186.207 0.625H184.379L181.143 15.625H182.971Z"
            fill="#00B2E6"
          />
          <path
            d="M187.742 15.625L190.978 0.625H189.15L185.914 15.625H187.742Z"
            fill="#00B2E6"
          />
          <path
            d="M197.691 15.625L200.927 0.625H199.104L195.868 15.625H197.691Z"
            fill="#00B2E6"
          />
          <path
            d="M202.509 15.625L205.745 0.625H203.923L200.687 15.625H202.509Z"
            fill="#00B2E6"
          />
          <path
            d="M207.129 15.625L210.365 0.625H208.537L205.301 15.625H207.129Z"
            fill="#00B2E6"
          />
          <path
            d="M212.259 15.625L215.495 0.625H213.667L210.431 15.625H212.259Z"
            fill="#00B2E6"
          />
          <path
            d="M217.077 15.625L220.313 0.625H218.485L215.254 15.625H217.077Z"
            fill="#00B2E6"
          />
          <path
            d="M236.55 15.625L239.786 0.625H237.958L234.722 15.625H236.55Z"
            fill="#00B2E6"
          />
          <path
            d="M222.141 15.625L225.377 0.625H223.549L220.313 15.625H222.141Z"
            fill="#00B2E6"
          />
          <path
            d="M226.96 15.625L230.196 0.625H228.368L225.132 15.625H226.96Z"
            fill="#00B2E6"
          />
          <path
            d="M231.731 15.625L234.967 0.625H233.139L229.903 15.625H231.731Z"
            fill="#00B2E6"
          />
          <path
            d="M241.68 15.625L244.916 0.625H243.088L239.857 15.625H241.68Z"
            fill="#00B2E6"
          />
          <path
            d="M246.499 15.625L249.735 0.625H247.907L244.676 15.625H246.499Z"
            fill="#00B2E6"
          />
          <path
            d="M251.118 15.625L254.354 0.625H252.526L249.29 15.625H251.118Z"
            fill="#00B2E6"
          />
          <path
            d="M256.248 15.625L259.484 0.625H257.656L254.42 15.625H256.248Z"
            fill="#00B2E6"
          />
          <path
            d="M261.066 15.625L264.302 0.625H262.474L259.238 15.625H261.066Z"
            fill="#00B2E6"
          />
          <path
            d="M280.406 15.625L283.642 0.625H281.814L278.578 15.625H280.406Z"
            fill="#A7C20B"
          />
          <path
            d="M265.993 15.625L269.229 0.625H267.406L264.17 15.625H265.993Z"
            fill="#A7C20B"
          />
          <path
            d="M270.81 15.625L274.051 0.625H272.223L268.987 15.625H270.81Z"
            fill="#A7C20B"
          />
          <path
            d="M275.583 15.625L278.824 0.625H276.996L273.76 15.625H275.583Z"
            fill="#A7C20B"
          />
          <path
            d="M285.537 15.625L288.773 0.625H286.945L283.709 15.625H285.537Z"
            fill="#A7C20B"
          />
          <path
            d="M290.355 15.625L293.591 0.625H291.763L288.527 15.625H290.355Z"
            fill="#A7C20B"
          />
          <path
            d="M294.968 15.625L298.204 0.625H296.382L293.146 15.625H294.968Z"
            fill="#A7C20B"
          />
          <path
            d="M300.104 15.625L303.34 0.625H301.512L298.276 15.625H300.104Z"
            fill="#A7C20B"
          />
          <path
            d="M304.923 15.625L308.159 0.625H306.331L303.095 15.625H304.923Z"
            fill="#A7C20B"
          />
          <path
            d="M324.39 15.625L327.626 0.625H325.804L322.568 15.625H324.39Z"
            fill="#A7C20B"
          />
          <path
            d="M309.982 15.625L313.218 0.625H311.395L308.159 15.625H309.982Z"
            fill="#A7C20B"
          />
          <path
            d="M314.799 15.625L318.036 0.625H316.213L312.977 15.625H314.799Z"
            fill="#A7C20B"
          />
          <path
            d="M319.572 15.625L322.808 0.625H320.985L317.749 15.625H319.572Z"
            fill="#A7C20B"
          />
          <path
            d="M329.526 15.625L332.762 0.625H330.934L327.698 15.625H329.526Z"
            fill="#A7C20B"
          />
          <path
            d="M334.344 15.625L337.58 0.625H335.753L332.516 15.625H334.344Z"
            fill="#A7C20B"
          />
          <path
            d="M338.958 15.625L342.194 0.625H340.366L337.13 15.625H338.958Z"
            fill="#A7C20B"
          />
          <path
            d="M344.089 15.625L347.325 0.625H345.502L342.266 15.625H344.089Z"
            fill="#A7C20B"
          />
          <path
            d="M348.907 15.625L352.143 0.625H350.32L347.084 15.625H348.907Z"
            fill="#A7C20B"
          />
          <path
            d="M368.247 15.625L371.483 0.625H369.655L366.424 15.625H368.247Z"
            fill="#33712C"
          />
          <path
            d="M353.838 15.625L357.074 0.625H355.246L352.01 15.625H353.838Z"
            fill="#33712C"
          />
          <path
            d="M358.656 15.625L361.892 0.625H360.064L356.828 15.625H358.656Z"
            fill="#33712C"
          />
          <path
            d="M363.429 15.625L366.665 0.625H364.837L361.601 15.625H363.429Z"
            fill="#33712C"
          />
          <path
            d="M373.378 15.625L376.614 0.625H374.791L371.555 15.625H373.378Z"
            fill="#33712C"
          />
          <path
            d="M378.196 15.625L381.432 0.625H379.609L376.373 15.625H378.196Z"
            fill="#33712C"
          />
          <path
            d="M382.814 15.625L386.05 0.625H384.222L380.986 15.625H382.814Z"
            fill="#33712C"
          />
          <path
            d="M387.945 15.625L391.181 0.625H389.353L386.122 15.625H387.945Z"
            fill="#33712C"
          />
          <path
            d="M392.763 15.625L395.999 0.625H394.171L390.94 15.625H392.763Z"
            fill="#33712C"
          />
          <path
            d="M412.236 15.625L415.472 0.625H413.644L410.408 15.625H412.236Z"
            fill="#33712C"
          />
          <path
            d="M397.827 15.625L401.063 0.625H399.235L395.999 15.625H397.827Z"
            fill="#33712C"
          />
          <path
            d="M402.645 15.625L405.881 0.625H404.053L400.817 15.625H402.645Z"
            fill="#33712C"
          />
          <path
            d="M407.418 15.625L410.654 0.625H408.826L405.59 15.625H407.418Z"
            fill="#33712C"
          />
          <path
            d="M417.367 15.625L420.603 0.625H418.775L415.544 15.625H417.367Z"
            fill="#33712C"
          />
          <path
            d="M422.185 15.625L425.421 0.625H423.598L420.362 15.625H422.185Z"
            fill="#33712C"
          />
          <path
            d="M426.804 15.625L430.04 0.625H428.212L424.976 15.625H426.804Z"
            fill="#33712C"
          />
          <path
            d="M431.934 15.625L435.171 0.625H433.343L430.106 15.625H431.934Z"
            fill="#33712C"
          />
          <path
            d="M436.752 15.625L439.988 0.625H438.16L434.924 15.625H436.752Z"
            fill="#33712C"
          />
          <path
            d="M455.979 15.625L459.215 0.625H457.387L454.156 15.625H455.979Z"
            fill="#81516E"
          />
          <path
            d="M441.57 15.625L444.806 0.625H442.978L439.742 15.625H441.57Z"
            fill="#81516E"
          />
          <path
            d="M446.389 15.625L449.625 0.625H447.797L444.566 15.625H446.389Z"
            fill="#81516E"
          />
          <path
            d="M451.161 15.625L454.397 0.625H452.569L449.333 15.625H451.161Z"
            fill="#81516E"
          />
          <path
            d="M461.11 15.625L464.346 0.625H462.523L459.287 15.625H461.11Z"
            fill="#81516E"
          />
          <path
            d="M465.928 15.625L469.164 0.625H467.341L464.105 15.625H465.928Z"
            fill="#81516E"
          />
          <path
            d="M470.547 15.625L473.783 0.625H471.955L468.719 15.625H470.547Z"
            fill="#81516E"
          />
          <path
            d="M475.677 15.625L478.914 0.625H477.086L473.855 15.625H475.677Z"
            fill="#81516E"
          />
          <path
            d="M480.496 15.625L483.732 0.625H481.904L478.673 15.625H480.496Z"
            fill="#81516E"
          />
          <path
            d="M499.969 15.625L503.205 0.625H501.377L498.141 15.625H499.969Z"
            fill="#81516E"
          />
          <path
            d="M485.559 15.625L488.796 0.625H486.968L483.731 15.625H485.559Z"
            fill="#81516E"
          />
          <path
            d="M490.378 15.625L493.614 0.625H491.786L488.55 15.625H490.378Z"
            fill="#81516E"
          />
          <path
            d="M495.15 15.625L498.386 0.625H496.558L493.322 15.625H495.15Z"
            fill="#81516E"
          />
          <path
            d="M505.099 15.625L508.335 0.625H506.507L503.276 15.625H505.099Z"
            fill="#81516E"
          />
          <path
            d="M509.918 15.625L513.154 0.625H511.331L508.095 15.625H509.918Z"
            fill="#81516E"
          />
          <path
            d="M514.536 15.625L517.772 0.625H515.944L512.708 15.625H514.536Z"
            fill="#81516E"
          />
          <path
            d="M519.667 15.625L522.903 0.625H521.075L517.839 15.625H519.667Z"
            fill="#81516E"
          />
          <path
            d="M524.485 15.625L527.721 0.625H525.893L522.657 15.625H524.485Z"
            fill="#81516E"
          />
          <path
            d="M543.758 15.625L546.994 0.625H545.171L541.935 15.625H543.758Z"
            fill="#DD004B"
          />
          <path
            d="M529.349 15.625L532.585 0.625H530.757L527.526 15.625H529.349Z"
            fill="#DD004B"
          />
          <path
            d="M534.168 15.625L537.404 0.625H535.576L532.345 15.625H534.168Z"
            fill="#DD004B"
          />
          <path
            d="M538.94 15.625L542.176 0.625H540.348L537.117 15.625H538.94Z"
            fill="#DD004B"
          />
          <path
            d="M548.889 15.625L552.13 0.625H550.302L547.066 15.625H548.889Z"
            fill="#DD004B"
          />
          <path
            d="M553.713 15.625L556.949 0.625H555.121L551.885 15.625H553.713Z"
            fill="#DD004B"
          />
          <path
            d="M558.326 15.625L561.562 0.625H559.734L556.498 15.625H558.326Z"
            fill="#DD004B"
          />
          <path
            d="M563.457 15.625L566.693 0.625H564.87L561.634 15.625H563.457Z"
            fill="#DD004B"
          />
          <path
            d="M568.275 15.625L571.511 0.625H569.688L566.452 15.625H568.275Z"
            fill="#DD004B"
          />
          <path
            d="M587.748 15.625L590.984 0.625H589.156L585.92 15.625H587.748Z"
            fill="#DD004B"
          />
          <path
            d="M573.339 15.625L576.575 0.625H574.747L571.511 15.625H573.339Z"
            fill="#DD004B"
          />
          <path
            d="M578.157 15.625L581.393 0.625H579.565L576.329 15.625H578.157Z"
            fill="#DD004B"
          />
          <path
            d="M582.93 15.625L586.166 0.625H584.338L581.102 15.625H582.93Z"
            fill="#DD004B"
          />
          <path
            d="M592.879 15.625L596.115 0.625H594.292L591.056 15.625H592.879Z"
            fill="#DD004B"
          />
          <path
            d="M597.697 15.625L600.933 0.625H599.11L595.874 15.625H597.697Z"
            fill="#DD004B"
          />
          <path
            d="M602.315 15.625L605.551 0.625H603.723L600.487 15.625H602.315Z"
            fill="#DD004B"
          />
          <path
            d="M607.445 15.625L610.681 0.625H608.853L605.617 15.625H607.445Z"
            fill="#DD004B"
          />
          <path
            d="M612.264 15.625L615.5 0.625H613.672L610.441 15.625H612.264Z"
            fill="#DD004B"
          />
        </g>
        <defs>
          <clipPath id="clip0_238_221">
            <rect
              width="615"
              height="15"
              fill="#10384F"
              transform="translate(0.5 0.625)"
            />
          </clipPath>
        </defs>
      </LinesDesktop>
      <LinesMobile
        width="234"
        height="16"
        viewBox="0 0 234 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_255_1299)">
          <path
            d="M0.599121 15.621H2.16261L5.35309 0.628906H3.78166L0.599121 15.621Z"
            fill="#10384F"
          />
          <path
            d="M5.35303 15.621H6.91652L10.0991 0.628906H8.53557L5.35303 15.621Z"
            fill="#10384F"
          />
          <path
            d="M10.0991 15.621H11.6626L14.8531 0.628906H13.2896L10.0991 15.621Z"
            fill="#10384F"
          />
          <path
            d="M14.853 15.625H16.4165L19.5991 0.625H18.0356L14.853 15.625Z"
            fill="#10384F"
          />
          <path
            d="M19.5991 15.625H21.1626L24.3531 0.625H22.7896L19.5991 15.625Z"
            fill="#10384F"
          />
          <path
            d="M24.353 15.625H25.9165L29.0991 0.625H27.5356L24.353 15.625Z"
            fill="#10384F"
          />
          <path
            d="M29.0991 15.625H30.6626L33.8452 0.625H32.2817L29.0991 15.625Z"
            fill="#10384F"
          />
          <path
            d="M33.8452 15.621H35.4166L38.5992 0.628906H37.0357L33.8452 15.621Z"
            fill="#00617F"
          />
          <path
            d="M38.5991 15.625H40.1626L43.3531 0.625H41.7817L38.5991 15.625Z"
            fill="#00617F"
          />
          <path
            d="M43.353 15.625H44.9165L48.107 0.625H46.5435L43.353 15.625Z"
            fill="#00617F"
          />
          <path
            d="M48.1069 15.625H49.6704L52.853 0.625H51.2895L48.1069 15.625Z"
            fill="#00617F"
          />
          <path
            d="M52.853 15.625H54.4165L57.607 0.625H56.0435L52.853 15.625Z"
            fill="#00617F"
          />
          <path
            d="M57.6069 15.625H59.1704L62.3609 0.625H60.7974L57.6069 15.625Z"
            fill="#00617F"
          />
          <path
            d="M62.3608 15.625H63.9243L67.1069 0.625H65.5434L62.3608 15.625Z"
            fill="#00617F"
          />
          <path
            d="M67.1069 15.621H68.6704L71.8609 0.628906H70.2895L67.1069 15.621Z"
            fill="#00BCFF"
          />
          <path
            d="M71.8608 15.621H73.4243L76.6148 0.628906H75.0434L71.8608 15.621Z"
            fill="#00BCFF"
          />
          <path
            d="M76.6152 15.625H78.1787L81.3692 0.625H79.7978L76.6152 15.625Z"
            fill="#00BCFF"
          />
          <path
            d="M81.3691 15.625H82.9326L86.1231 0.625H84.5517L81.3691 15.625Z"
            fill="#00BCFF"
          />
          <path
            d="M86.123 15.625H87.6945L90.877 0.625H89.3135L86.123 15.625Z"
            fill="#00BCFF"
          />
          <path
            d="M90.877 15.625H92.4404L95.6309 0.625H94.0595L90.877 15.625Z"
            fill="#00BCFF"
          />
          <path
            d="M95.6309 15.625H97.2023L100.385 0.625H98.8213L95.6309 15.625Z"
            fill="#00BCFF"
          />
          <path
            d="M100.385 15.621H101.948L105.131 0.628906H103.567L100.385 15.621Z"
            fill="#66B512"
          />
          <path
            d="M105.131 15.621H106.694L109.885 0.628906H108.321L105.131 15.621Z"
            fill="#66B512"
          />
          <path
            d="M109.885 15.625H111.448L114.639 0.625H113.075L109.885 15.625Z"
            fill="#66B512"
          />
          <path
            d="M114.639 15.625H116.202L119.393 0.625H117.821L114.639 15.625Z"
            fill="#66B512"
          />
          <path
            d="M119.393 15.625H120.956L124.147 0.625H122.583L119.393 15.625Z"
            fill="#66B512"
          />
          <path
            d="M124.146 15.625H125.71L128.9 0.625H127.329L124.146 15.625Z"
            fill="#66B512"
          />
          <path
            d="M128.901 15.625H130.464L133.655 0.625H132.091L128.901 15.625Z"
            fill="#66B512"
          />
          <path
            d="M133.655 15.621H135.218L138.401 0.628906H136.837L133.655 15.621Z"
            fill="#2B6636"
          />
          <path
            d="M138.401 15.621H139.964L143.155 0.628906H141.591L138.401 15.621Z"
            fill="#2B6636"
          />
          <path
            d="M143.155 15.621H144.718L147.901 0.628906H146.337L143.155 15.621Z"
            fill="#2B6636"
          />
          <path
            d="M147.901 15.625H149.464L152.647 0.625H151.083L147.901 15.625Z"
            fill="#2B6636"
          />
          <path
            d="M152.647 15.625H154.21L157.401 0.625H155.837L152.647 15.625Z"
            fill="#2B6636"
          />
          <path
            d="M157.401 15.625H158.964L162.147 0.625H160.583L157.401 15.625Z"
            fill="#2B6636"
          />
          <path
            d="M162.147 15.625H163.71L166.901 0.625H165.337L162.147 15.625Z"
            fill="#2B6636"
          />
          <path
            d="M166.901 15.621H168.464L171.647 0.628906H170.083L166.901 15.621Z"
            fill="#624963"
          />
          <path
            d="M171.647 15.621H173.21L176.401 0.628906H174.837L171.647 15.621Z"
            fill="#624963"
          />
          <path
            d="M176.401 15.621H177.964L181.147 0.628906H179.583L176.401 15.621Z"
            fill="#624963"
          />
          <path
            d="M181.147 15.625H182.71L185.893 0.625H184.33L181.147 15.625Z"
            fill="#624963"
          />
          <path
            d="M185.893 15.625H187.456L190.647 0.625H189.083L185.893 15.625Z"
            fill="#624963"
          />
          <path
            d="M190.647 15.625H192.21L195.393 0.625H193.83L190.647 15.625Z"
            fill="#624963"
          />
          <path
            d="M195.393 15.625H196.956L200.147 0.625H198.583L195.393 15.625Z"
            fill="#624963"
          />
          <path
            d="M200.147 15.621H201.718L204.901 0.628906H203.337L200.147 15.621Z"
            fill="#D30F4B"
          />
          <path
            d="M204.901 15.625H206.464L209.647 0.625H208.083L204.901 15.625Z"
            fill="#D30F4B"
          />
          <path
            d="M209.647 15.625H211.21L214.401 0.625H212.837L209.647 15.625Z"
            fill="#D30F4B"
          />
          <path
            d="M214.401 15.625H215.964L219.147 0.625H217.583L214.401 15.625Z"
            fill="#D30F4B"
          />
          <path
            d="M219.147 15.625H220.71L223.901 0.625H222.337L219.147 15.625Z"
            fill="#D30F4B"
          />
          <path
            d="M223.901 15.625H225.464L228.647 0.625H227.083L223.901 15.625Z"
            fill="#D30F4B"
          />
          <path
            d="M228.647 15.625H230.21L233.401 0.625H231.837L228.647 15.625Z"
            fill="#D30F4B"
          />
        </g>
        <defs>
          <clipPath id="clip0_255_1299">
            <rect
              width="232.802"
              height="15"
              fill="#10384F"
              transform="translate(0.599121 0.625)"
            />
          </clipPath>
        </defs>
      </LinesMobile>
    </>
  );
};

export default Lines;
