import React, { FunctionComponent } from "react";
import * as Styled from "./Footer.styled";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import LinkedInIcon from "./icons/LinkedInIcon";
import Lines from "../../components/Lines/Lines";

type FooterProps = {
  type?: "lime" | "teal";
};

const Footer: FunctionComponent<FooterProps> = ({ type = "teal" }) => {
  const openCookiesSettings = () => {
    // @ts-ignore
    klaro.show();
  };

  return (
    <Styled.Footer formType={type}>
      <Styled.LinkedinButton
        href="https://www.linkedin.com/showcase/digitalhubwarsawbayer"
        target={"_blank"}
        aria-label="See LinkedIn account"
      >
        <LinkedInIcon />
      </Styled.LinkedinButton>

      <Styled.Lines>
        <Lines />
      </Styled.Lines>
      <Styled.Container>
        <Styled.Text>
          ©{new Date().getFullYear()} Digital Hub Warsaw at Bayer
        </Styled.Text>
        <Styled.Icons>
          <a
            href="https://www.linkedin.com/showcase/digitalhubwarsawbayer"
            target={"_blank"}
            aria-label="See LinkedIn account"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.facebook.com/dhubwarsaw"
            target={"_blank"}
            aria-label="See Facebook account"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/dhubwarsaw/"
            target={"_blank"}
            aria-label="See Instagram account"
          >
            <InstagramIcon />
          </a>
        </Styled.Icons>
        <Styled.Links>
          <Styled.Link
            href="/Data_Privacy_Statement_PL_ENG.pdf"
            target={"_blank"}
            aria-label="Open Privacy Policy"
            formType={"lime"}
          >
            Privacy Policy
          </Styled.Link>
          <Styled.Link
            id="cc-settings"
            type={"button"}
            aria-label="Open cookies settings"
            data-cc="c-settings"
            formType={"lime"}
            onClick={() => openCookiesSettings()}
          >
            Cookies
          </Styled.Link>
        </Styled.Links>
      </Styled.Container>
    </Styled.Footer>
  );
};

export default Footer;
