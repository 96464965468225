import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import OgImage from "../../images/ogimage.png";

type Props = {
  title?: string;
};

const Seo: FunctionComponent<Props> = ({ title, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  return (
    <Helmet
      htmlAttributes={{
        lang: "pl",
      }}
      titleTemplate={site.siteMetadata.title}
      title={site.siteMetadata.title}
    >
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="og:title" content={site.siteMetadata.title} />
      <meta name="og:description" content={site.siteMetadata.description} />
      <meta name="og:type" content={"website"} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta
        data-hid="og:image:type"
        property="og:image:type"
        content="image/png"
      />
      <meta
        property="og:image"
        content={`https://techwizards.com.pl${OgImage}`}
      />
      <meta property="og:image:alt" content={site.siteMetadata.title} />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="576" />
      <meta
        name="facebook-domain-verification"
        content="sh86apgrabq8m9mtahmrdd3kx7myvy"
      />
    </Helmet>
  );
};

export default Seo;
