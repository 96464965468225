import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import {
  AppContainer,
  LinkHoverBefore,
  mediaDown,
} from "../../../styles/Mixins";

export const Nav = styled.nav`
  width: 100%;
`;

type NavAnimatedProps = {
  isVisible: boolean;
};

export const NavAnimated = styled.div<NavAnimatedProps>`
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 200;
  overflow: hidden;
  -webkit-box-shadow: 0 15px 105px -15px ${ThemeStyle.colors.teal};
  -moz-box-shadow: 0 15px 105px -15px ${ThemeStyle.colors.teal};
  box-shadow: 0 15px 105px -15px ${ThemeStyle.colors.teal};
  transition: ${ThemeStyle.transition};
  transition-property: top;
  background: rgba(15, 175, 165, 0.3); //teal
  backdrop-filter: blur(1rem);
  top: -100px;
  ${(props) =>
    props.isVisible &&
    css`
      top: 0;
    `}
`;

export const NavStatic = styled.div`
  width: 100%;
  position: absolute;
  top: 4.5rem;
  left: 0;
  z-index: 100;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      top: 0;

      a {
        transform: scale(0.7) translateX(25%);
      }
    `
  )};
`;

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

type FormPage = {
  type?: string;
};

export const DHubWrapper = styled.div<FormPage>`
  transform: translateY(5px);
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      svg {
        width: 15rem;
      }
    `
  )};
  ${(p: FormPage) =>
    p.type === "formPage" &&
    css`
      ${mediaDown(
        ThemeStyle.breakpoints.md,
        css`
          svg {
            width: 28rem;
          }
        `
      )}
      ${mediaDown(
        ThemeStyle.breakpoints.sm,
        css`
          svg {
            width: 14rem;
          }
        `
      )};
    `};
`;

// only on form page
export const Row = styled.div`
  display: flex;
  align-items: center;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      a {
        padding: 2rem;
      }
    `
  )};
`;

export const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-left: 6rem;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      display: none;
    `
  )};
`;

export const Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: ${ThemeStyle.fontWeight.medium};
  color: ${ThemeStyle.colors.white};
  padding: 1.5rem 2rem;
  cursor: pointer;

  &::before {
    ${LinkHoverBefore};
  }

  &:hover {
    &::before {
      width: 30%;
    }
  }

  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      font-size: 1.4rem;
    `
  )};
`;

export const Decoration = styled.p`
  width: 1px;
  height: 15px;
  margin: 0 5rem 0 2rem;
  background-color: ${ThemeStyle.colors.white};
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      display: none;
    `
  )};
`;

type LogoProps = {
  type?: string;
};

export const BayerWrapper = styled.div<LogoProps>`
  margin-left: 4rem;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      display: none;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )};
  ${(p: LogoProps) =>
    p.type === "formPage" &&
    css`
      ${mediaDown(
        ThemeStyle.breakpoints.lg,
        css`
          display: block;
        `
      )};
      ${mediaDown(
        ThemeStyle.breakpoints.sm,
        css`
          margin-left: 2rem;
          svg {
            width: 4rem;
          }
        `
      )};
    `};
`;
