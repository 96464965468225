import { createGlobalStyle } from "styled-components";
import ThemeStyle from "./ThemeStyle";
import "swiper/css";
import BebasNeueBookWoff from "../fonts/BebasNeue-Book.woff";
import BebasNeueBookWoff2 from "../fonts/BebasNeue-Book.woff2";
import BebasNeueRegularWoff from "../fonts/BebasNeue-Regular.woff";
import BebasNeueRegularWoff2 from "../fonts/BebasNeue-Regular.woff2";
import BebasNeueBoldWoff from "../fonts/BebasNeue-Bold.woff";
import BebasNeueBoldWoff2 from "../fonts/BebasNeue-Bold.woff2";

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font: inherit;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input {
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${ThemeStyle.colors.blue} inset !important;
    -webkit-text-fill-color: ${ThemeStyle.colors.white} !important;
    animation: onAutoFillStart 5s ease-in-out;
  }
  
  @keyframes onAutoFillStart {
    from {
      background-color: #fff;
    }
    to {
      background-color: #fff;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  html {
    scroll-behavior: smooth;
    font-size: 10px;
  }

  body {
    position: relative;
    font-family: ${ThemeStyle.fonts.main};
    font-weight: ${ThemeStyle.fontWeight.regular};
    letter-spacing: 0.02em;
    line-height: 1.5;
    font-style: normal;
    font-size: 1.6rem;
    background-color: #e0e0e0;

    * {
      box-sizing: border-box;
    }
    
    main {
      overflow-x: clip;
    }
    
    strong {
      font-weight: ${ThemeStyle.fontWeight.bold};
    }
    
    a {
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    img {
      max-width: 100%;
    }
  }

  @font-face {
    font-family: 'BebasNeue';
    src: url(${BebasNeueBookWoff2}) format('woff2'),
    url(${BebasNeueBookWoff}) format('woff');
    font-weight: ${ThemeStyle.fontWeight.light};
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'BebasNeue';
    src: url(${BebasNeueRegularWoff2}) format('woff2'),
    url(${BebasNeueRegularWoff}) format('woff');
    font-weight: ${ThemeStyle.fontWeight.regular};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'BebasNeue';
    src: url(${BebasNeueBoldWoff2}) format('woff2'),
    url(${BebasNeueBoldWoff}) format('woff');
    font-weight: ${ThemeStyle.fontWeight.bold};
    font-style: normal;
    font-display: swap;
  }
`;

export default GlobalStyle;
