import styled, { css } from "styled-components";
import { AppContainer, LinkHoverBefore, mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";

type FooterProps = {
  formType?: "lime" | "teal";
};

export const Footer = styled.footer<FooterProps>`
  position: relative;
  background-color: ${ThemeStyle.colors.teal};
  z-index: 4;
  ${(p: FooterProps) =>
    p.formType === "lime" &&
    css`
      background-color: ${ThemeStyle.colors.lime};
    `};
`;

export const LinkedinButton = styled.a`
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  background: #00a9e6;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: scale(1.2);
  svg {
    transform: translate(1px, -1px);
  }
  &:before {
    content: "Follow our LinkedIn!";
    position: absolute;
    top: -3rem;
    right: 0;
    padding: 0.5rem 1rem;
    border-radius: 8rem;
    font-size: 1rem;
    background: #fff;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    transition-property: visibility, opacity;
  }
  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      right: 2rem;
      bottom: 2rem;
      transform: scale(0.9);
    `
  )}
`;

export const Container = styled.div`
  ${AppContainer};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0 3.5rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 2rem 0 1.5rem;
      grid-template-columns: 1fr;
    `
  )}
`;

const TextBase = css`
  font-size: 1.6rem;
  color: ${ThemeStyle.colors.white};
  font-weight: ${ThemeStyle.fontWeight.light};
`;

export const Text = styled.p`
  ${TextBase};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      text-align: center;
    `
  )}
`;

export const Links = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      justify-content: center;
    `
  )}
`;

export const Link = styled.a<FooterProps>`
  ${TextBase};
  position: relative;
  &:first-of-type {
    margin-right: 2rem;
  }
  &::before {
    ${LinkHoverBefore};
    bottom: -6px;
    ${(p: FooterProps) =>
      p.formType === "lime" &&
      css`
        background-color: ${ThemeStyle.colors.navy};
      `};
  }
  &:hover {
    &:before {
      width: 50%;
    }
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      &:first-of-type {
        margin-right: 3rem;
      }
    `
  )}
`;

export const Icons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  width: fit-content;
  justify-self: center;
  a {
    height: 100%;
    display: flex;
    svg path {
      transition: ${ThemeStyle.transition};
      transition-property: fill;
      fill: ${ThemeStyle.colors.white};
    }

    &:hover {
      svg path {
        fill: ${ThemeStyle.colors.navy};
      }
    }
  }
`;

export const Lines = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
`;
