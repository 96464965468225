import React, { FunctionComponent } from "react";
import * as Styled from "./Logo.styled";

type Props = {
  color?: "white" | "black";
};

const DHubLogo: FunctionComponent<Props> = ({ color = "white" }) => {
  return (
    <Styled.Container>
      <svg
        width="309"
        height="47"
        viewBox="0 0 309 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_238_151)">
          <path
            d="M40.4491 9.23486L38.2744 19.4568H39.3617L41.5175 9.23486H40.4491Z"
            fill={color}
          />
          <path
            d="M159.705 0.114014L150.278 44.8646H151.366L160.773 0.114014H159.705Z"
            fill={color}
          />
          <path
            d="M37.631 9.23486L35.4658 19.4568H36.5437L38.7089 9.23486H37.631Z"
            fill={color}
          />
          <path
            d="M34.8232 9.23486L32.6485 19.4568H33.7358L35.8916 9.23486H34.8232Z"
            fill={color}
          />
          <path
            d="M32.0049 9.23486L29.8397 19.4568H30.9175L33.0827 9.23486H32.0049Z"
            fill={color}
          />
          <path
            d="M29.1971 9.23486L27.0225 19.4568H28.1098L30.2655 9.23486H29.1971Z"
            fill={color}
          />
          <path
            d="M26.3893 9.23486L24.2147 19.4568H25.2925L27.4577 9.23486H26.3893Z"
            fill={color}
          />
          <path
            d="M23.5711 9.23486L21.3965 19.4568H22.4838L24.6396 9.23486H23.5711Z"
            fill={color}
          />
          <path
            d="M20.7635 9.23486L18.5889 19.4568H19.6667L21.8319 9.23486H20.7635Z"
            fill={color}
          />
          <path
            d="M17.9465 9.23486L15.7812 19.4568H16.8591L19.0243 9.23486H17.9465Z"
            fill={color}
          />
          <path
            d="M15.1375 9.23486L12.9628 19.4568H14.0407L16.2059 9.23486H15.1375Z"
            fill={color}
          />
          <path
            d="M12.367 9.23486L10.1924 19.4568H11.2797L13.4355 9.23486H12.367Z"
            fill={color}
          />
          <path
            d="M9.59684 9.23486L7.43164 19.4568H8.50951L10.6747 9.23486H9.59684Z"
            fill={color}
          />
          <path
            d="M6.83567 9.23486L4.66101 19.4568H5.73889L7.90409 9.23486H6.83567Z"
            fill={color}
          />
          <path
            d="M4.06565 9.23486L1.90045 19.4568H2.97833L5.14353 9.23486H4.06565Z"
            fill={color}
          />
          <path
            d="M85.1145 0.11377L82.59 12.0251H68.0765L70.601 0.11377H66.7056L60.626 28.8339H64.5214L67.3485 15.5463H81.862L79.0349 28.8339H82.9304L89.01 0.11377H85.1145Z"
            fill={color}
          />
          <path
            d="M111.635 0.0854492L107.655 19.1626C106.737 23.6234 103.967 25.8823 99.4382 25.8823C96.9137 25.8823 94.8998 25.2179 93.7463 24.0125C92.9237 23.1394 92.536 22.0004 92.5928 20.5768C92.5928 19.7795 92.6779 19.0297 92.848 18.3274L96.6679 0.113923H92.7724L88.9715 18.5457C88.8202 19.3809 88.7351 20.2066 88.7351 20.8805C88.7351 27.1636 94.323 29.3845 99.0978 29.3845C105.962 29.3845 110.122 26.1006 111.474 19.6182L115.531 0.0854492H111.635Z"
            fill={color}
          />
          <path
            d="M134.498 13.9046C137.268 12.7182 138.97 10.1746 138.97 7.01402C138.97 2.62913 136.011 0.104492 130.839 0.104492H119.729L113.508 28.8056H126.102C133.486 28.8056 137.722 25.4363 137.722 19.5708C137.722 17.0271 136.465 14.9201 134.507 13.9046H134.498ZM121.072 12.1392L122.897 3.6257H130.81C132.777 3.6257 135.122 4.2616 135.122 7.28927C135.122 11.5982 130.347 12.0918 128.9 12.1392H121.072ZM133.864 19.865C133.864 23.8797 129.997 25.3129 126.688 25.3129H118.207L120.325 15.632H128.04C132.077 15.632 133.874 16.9417 133.874 19.865H133.864Z"
            fill={color}
          />
          <path
            d="M47.1519 0H33.9432L32.3736 7.39358H44.5328C47.5017 7.39358 49.7142 8.39964 49.7142 12.7655C49.7142 15.4231 48.3905 21.4404 42.5945 21.4404H1.61641L0.046875 28.815H42.1123C52.1252 28.815 58.0252 22.2756 58.5546 12.7086C58.999 3.3124 52.494 0 47.1519 0Z"
            fill={color}
          />
          <path
            d="M6.07959 36.3984H5.05844L0 44.6937H1.18188L2.64741 42.207H6.10795L6.51452 44.6937H7.60185L6.10795 36.5123L6.08904 36.3984H6.07959ZM5.94722 41.2769H3.18635L5.34209 37.5564L5.94722 41.2769Z"
            fill={color}
          />
          <path
            d="M11.3173 38.4485C9.2656 38.4485 8.0459 40.4226 8.0459 42.3398C8.0459 43.9249 8.92522 44.874 10.4002 44.874C11.8752 44.874 12.7734 44.1526 13.2367 42.729L13.2934 42.5487H12.2912L12.2628 42.6436C11.9697 43.4978 11.3268 43.9913 10.4948 43.9913C9.5587 43.9913 9.01977 43.3934 9.01977 42.3398C9.01977 40.8402 9.8329 39.3312 11.3835 39.3312C11.7901 39.3312 12.1305 39.464 12.3479 39.7013C12.5181 39.8816 12.6127 40.1284 12.6221 40.4132V40.5745H12.7545V40.6789H13.6055V40.546C13.6244 39.9386 13.4447 39.4356 13.0949 39.0749C12.6883 38.6668 12.0737 38.4485 11.3173 38.4485Z"
            fill={color}
          />
          <path
            d="M17.0664 38.4485C15.0146 38.4485 13.7949 40.4226 13.7949 42.3398C13.7949 43.9249 14.6742 44.874 16.1492 44.874C17.6242 44.874 18.5224 44.1526 18.9857 42.729L19.0425 42.5487H18.0402L18.0119 42.6436C17.7188 43.4978 17.0758 43.9913 16.2438 43.9913C15.3077 43.9913 14.7688 43.3934 14.7688 42.3398C14.7688 40.8402 15.5819 39.3312 17.1325 39.3312C17.5391 39.3312 17.8795 39.464 18.097 39.7013C18.2672 39.8816 18.3617 40.1284 18.3712 40.4132V40.5745H18.5035V40.6789H19.3545V40.546C19.3734 39.9386 19.1937 39.4356 18.8439 39.0749C18.4373 38.6668 17.8228 38.4485 17.0664 38.4485Z"
            fill={color}
          />
          <path
            d="M22.8437 38.4485C20.7163 38.4485 19.5723 40.4037 19.5723 42.2544C19.5723 43.8774 20.5083 44.8455 22.0684 44.8455C23.4394 44.8455 24.3849 44.1147 24.8198 42.729L24.8765 42.5487H23.8743L23.8459 42.6436C23.5623 43.4883 22.9004 43.9913 22.0778 43.9913C21.1229 43.9913 20.5461 43.3649 20.5461 42.3114C20.5461 42.188 20.5461 42.0836 20.5556 41.9887H25.094L25.1129 41.8748C25.1696 41.5141 25.1696 41.1629 25.1696 40.8402C25.1696 39.3881 24.262 38.4485 22.8437 38.4485ZM20.669 41.1345C20.9432 40.2138 21.6618 39.3312 22.7019 39.3312C23.5907 39.3312 24.1485 39.9006 24.1485 40.8118V41.1345H20.669Z"
            fill={color}
          />
          <path
            d="M26.9279 36.3984L25.1504 44.6937H26.1432L27.9302 36.3984H26.9279Z"
            fill={color}
          />
          <path
            d="M30.7761 38.4485C28.6488 38.4485 27.5047 40.4037 27.5047 42.2544C27.5047 43.8774 28.4407 44.8455 30.0008 44.8455C31.3718 44.8455 32.3173 44.1147 32.7522 42.729L32.809 42.5487H31.8067L31.7784 42.6436C31.4947 43.4883 30.8329 43.9913 30.0103 43.9913C29.0553 43.9913 28.4786 43.3649 28.4786 42.3114C28.4786 42.188 28.4786 42.0836 28.488 41.9887H33.0264L33.0454 41.8748C33.1021 41.5141 33.1021 41.1629 33.1021 40.8402C33.1021 39.3881 32.1944 38.4485 30.7761 38.4485ZM28.6015 41.1345C28.8757 40.2138 29.5943 39.3312 30.6343 39.3312C31.5231 39.3312 32.0809 39.9006 32.0809 40.8118V41.1345H28.6015Z"
            fill={color}
          />
          <path
            d="M37.3375 39.5305L37.536 38.5339H36.9687C36.2029 38.5339 35.6072 38.8187 35.1156 39.4546L35.2952 38.6194H34.3781L33.1111 44.6937H34.1039L34.7846 41.5521C34.9075 40.9542 35.3614 39.559 36.808 39.521H37.3186L37.3375 39.5305Z"
            fill={color}
          />
          <path
            d="M41.7809 41.8748L41.8471 41.5995C41.97 41.0775 42.1307 40.3657 42.1307 39.8816C42.1307 38.8186 40.9772 38.4485 39.9844 38.4485C38.5945 38.4485 37.649 39.1413 37.4316 40.3277H37.4032V40.4891H37.5356V40.5935H38.4338L38.4527 40.4796C38.604 39.502 39.4455 39.2932 40.1262 39.2932C40.6179 39.2932 41.1947 39.4166 41.1947 39.9955C41.1947 40.7074 40.9867 40.8972 40.6085 40.9921C40.3815 41.0491 40.1073 41.0585 39.7953 41.0775C39.4928 41.0965 39.1524 41.106 38.7836 41.1629C37.8665 41.3148 36.6562 41.6755 36.6562 43.0896C36.6562 44.2191 37.3559 44.8455 38.6324 44.8455C39.3604 44.8455 40.0506 44.5418 40.5139 44.0482C40.5328 44.5038 40.7976 44.7601 41.2514 44.7601C41.4878 44.7601 41.7714 44.7126 41.8471 44.7031L41.9416 44.6842L42.1213 43.83L41.9133 43.8869C41.8092 43.9154 41.7714 43.9154 41.658 43.9154C41.4216 43.9154 41.4216 43.8584 41.4216 43.8205C41.4216 43.6211 41.4689 43.4218 41.5634 42.9852C41.6201 42.7195 41.6958 42.3683 41.7998 41.8843L41.7809 41.8748ZM40.8448 41.7419L40.703 42.4253C40.4761 43.4408 39.7764 44.0198 38.7647 44.0198C38.3298 44.0198 37.6112 43.9059 37.6112 43.1181C37.6112 42.15 38.5945 42.0077 39.6819 41.9317H39.767C39.8521 41.9127 39.9466 41.9033 40.0506 41.8938C40.3059 41.8653 40.6085 41.8368 40.8448 41.7419Z"
            fill={color}
          />
          <path
            d="M44.0321 43.8964C43.8146 43.8964 43.7957 43.6876 43.7957 43.6307C43.7957 43.4788 43.8146 43.3839 43.8714 43.1561L44.6656 39.4925H45.8475L46.0177 38.6099H44.8736L45.2424 36.854H44.2401L43.8714 38.6099H42.8313L42.6611 39.4925H43.6917L42.9259 43.1656C42.8691 43.4124 42.8313 43.6591 42.8313 43.8015C42.8313 44.4469 43.2379 44.7791 44.0321 44.7791C44.2685 44.7791 44.5238 44.7506 44.7696 44.7221H44.8736L45.0438 43.8205L44.8263 43.8395C44.5616 43.8679 44.2874 43.8964 44.0416 43.8964H44.0321Z"
            fill={color}
          />
          <path
            d="M47.7101 38.6194H46.7173L45.4598 44.5323L45.422 44.6937H46.4148L47.6817 38.7807L47.7101 38.6194Z"
            fill={color}
          />
          <path
            d="M48.2018 36.3984H47.209L46.8875 37.7936H47.8803L48.2018 36.3984Z"
            fill={color}
          />
          <path
            d="M51.4255 38.477C50.7353 38.477 50.0545 38.7997 49.5629 39.3312L49.6953 38.6194H48.7592L47.4639 44.7222H48.4661L49.1185 41.6376C49.4116 40.2898 50.2909 39.3502 51.2553 39.3502C51.5768 39.3502 52.3238 39.4356 52.3238 40.2519C52.3238 40.4322 52.2954 40.641 52.2387 40.8023L51.3972 44.7222H52.3994L53.2314 40.8878C53.2882 40.5935 53.3165 40.3563 53.3165 40.1664C53.3165 39.0845 52.6263 38.4675 51.4255 38.4675V38.477Z"
            fill={color}
          />
          <path
            d="M58.7531 39.4356C58.4128 38.7332 57.5902 38.4105 56.8621 38.4105C54.9238 38.4105 53.7609 40.2518 53.7609 42.0361C53.7609 43.5547 54.6497 44.5418 56.0206 44.5418C56.5785 44.5418 57.2592 44.333 57.732 43.8774C57.4767 44.893 57.1458 46.1173 55.7275 46.1173C55.0562 46.1173 54.2525 45.918 54.2525 44.9879V44.855H53.2598V44.9974C53.2881 46.2787 54.1485 47.0095 55.6141 47.0095C57.3916 47.0095 58.2142 46.1932 58.6491 43.9818L59.8688 38.5909H58.9233L58.7342 39.4356H58.7531ZM58.3088 40.9446C58.3088 42.2734 57.4389 43.6971 56.1436 43.6971C54.8482 43.6971 54.7253 42.4063 54.7253 42.0171C54.7253 40.7264 55.5384 39.3217 56.8621 39.3217C57.8171 39.3217 58.3088 39.8722 58.3088 40.9446Z"
            fill={color}
          />
          <path
            d="M70.9033 36.3984H64.3794L64.2186 37.3286H66.9417L65.3343 44.6937H66.3649L67.9723 37.3286H70.7048L70.9033 36.3984Z"
            fill={color}
          />
          <path
            d="M72.8036 39.5589L73.0021 38.5624H72.4348C71.6689 38.5624 71.0733 38.8471 70.5816 39.483L70.7613 38.6478H69.8441L68.5771 44.7221H69.5699L70.2507 41.5806C70.3736 40.9826 70.8275 39.5874 72.2741 39.5495H72.7846L72.8036 39.5589Z"
            fill={color}
          />
          <path
            d="M77.2858 41.8748L77.3519 41.5995C77.4749 41.0775 77.6356 40.3562 77.6356 39.8816C77.6356 38.8186 76.4821 38.4485 75.4893 38.4485C74.0994 38.4485 73.1539 39.1413 72.9364 40.3277H72.9081V40.4891H73.0405V40.5935H73.9387L73.9576 40.4796C74.1089 39.502 74.9504 39.2932 75.6311 39.2932C76.1228 39.2932 76.6996 39.4166 76.6996 39.9955C76.6996 40.7074 76.4915 40.8972 76.1133 40.9921C75.8864 41.0491 75.6122 41.0585 75.3002 41.0775C74.9976 41.0965 74.6573 41.106 74.2885 41.1629C73.3808 41.3148 72.1611 41.685 72.1611 43.0896C72.1611 44.2191 72.8608 44.8455 74.1372 44.8455C74.8653 44.8455 75.5555 44.5418 76.0188 44.0482C76.0377 44.5038 76.3024 44.7601 76.7563 44.7601C76.9927 44.7601 77.2763 44.7126 77.3519 44.7031L77.4465 44.6842L77.6261 43.83L77.4181 43.8869C77.3236 43.9154 77.2763 43.9154 77.1628 43.9154C76.9265 43.9154 76.9265 43.8584 76.9265 43.8205C76.9265 43.6211 76.9737 43.4218 77.0683 42.9947C77.125 42.729 77.2007 42.3778 77.3047 41.8843L77.2858 41.8748ZM76.3213 41.7419L76.1795 42.4253C75.9526 43.4408 75.2529 44.0198 74.2412 44.0198C73.8063 44.0198 73.0877 43.9059 73.0877 43.1181C73.0877 42.15 74.071 42.0077 75.1584 41.9317H75.2435C75.3286 41.9127 75.4231 41.9033 75.5177 41.8938C75.773 41.8653 76.0755 41.8368 76.3119 41.7419H76.3213Z"
            fill={color}
          />
          <path
            d="M81.8337 38.5055C81.1435 38.5055 80.4627 38.8282 79.971 39.3597L80.1034 38.6478H79.1674L77.872 44.7506H78.8742L79.5266 41.666C79.8197 40.3183 80.6991 39.3786 81.6635 39.3786C81.985 39.3786 82.7319 39.4641 82.7319 40.2803C82.7319 40.4322 82.7035 40.6505 82.6468 40.8308L81.8053 44.7506H82.8075L83.6396 40.9162C83.6963 40.622 83.7247 40.3847 83.7247 40.1949C83.7247 39.1129 83.0345 38.496 81.8337 38.496V38.5055Z"
            fill={color}
          />
          <path
            d="M86.8634 38.477C85.9557 38.477 84.6036 38.9326 84.6036 40.2044C84.6036 41.3149 85.6248 41.7135 86.2394 41.9508L86.4379 42.0267C87.1187 42.283 87.7049 42.5013 87.7049 43.0897C87.7049 43.7731 86.8918 44.0198 86.3717 44.0198C85.691 44.0198 84.8684 43.792 84.8684 42.6816V42.5487H83.8851V42.6816C83.8567 43.308 84.0269 43.8205 84.3956 44.2097C84.8117 44.6368 85.4357 44.874 86.1732 44.874C87.3645 44.874 88.6409 44.3805 88.6409 42.9758C88.6409 41.8938 87.6671 41.5237 86.8823 41.22L86.6932 41.144C86.0597 40.8783 85.5681 40.6505 85.5681 40.2044C85.5681 39.4166 86.5608 39.3597 86.8728 39.3597C87.2983 39.3597 87.6482 39.4831 87.8562 39.7014C88.0358 39.8912 88.1115 40.157 88.0925 40.4796V40.622H89.0853V40.4891C89.1042 39.8817 88.934 39.3977 88.6031 39.056C88.2249 38.6668 87.6482 38.4675 86.8823 38.4675L86.8634 38.477Z"
            fill={color}
          />
          <path
            d="M92.8669 38.6194H91.6945L91.8363 37.974C91.9308 37.5089 92.0916 37.2716 92.7061 37.2716C92.8291 37.2716 92.9614 37.3001 93.1127 37.3286L93.2451 37.357L93.4058 36.5313L93.3018 36.4934C93.0465 36.3984 92.8007 36.3984 92.536 36.3984C91.5715 36.3984 91.0515 36.8635 90.853 37.8885L90.7017 38.6194H89.6994L89.4914 39.502H90.5031L89.3874 44.5323L89.3496 44.6937H90.3235L91.477 39.502H92.5832L92.8763 38.6194H92.8669Z"
            fill={color}
          />
          <path
            d="M95.4962 38.4485C93.2932 38.4485 92.1396 40.3942 92.1396 42.3114C92.1396 43.9154 93.0473 44.874 94.579 44.874C96.8672 44.874 97.9072 42.7954 97.9072 40.8687C97.9072 39.4356 96.9617 38.4865 95.4962 38.4485ZM94.7209 43.9913H94.6831C93.6619 43.9913 93.0946 43.3744 93.0946 42.2544C93.0946 41.0775 93.8132 39.3312 95.3449 39.3312C96.3377 39.3312 96.905 39.9481 96.905 41.0111C96.905 41.9412 96.5362 42.8998 95.9784 43.4598C95.6191 43.811 95.1936 43.9913 94.7209 43.9913Z"
            fill={color}
          />
          <path
            d="M102.246 39.5589L102.445 38.5624H101.878C101.112 38.5624 100.516 38.8471 100.025 39.483L100.204 38.6478H99.2965L98.0295 44.7221H99.0318L99.7125 41.5806C99.8355 40.9826 100.289 39.5874 101.736 39.5495H102.246V39.5589Z"
            fill={color}
          />
          <path
            d="M108.893 38.477C108.146 38.477 107.456 38.8472 106.936 39.54C106.766 38.8567 106.246 38.477 105.442 38.477C104.78 38.477 104.109 38.7902 103.627 39.3027L103.778 38.6479H102.833L101.48 44.7222H102.483L103.135 41.666C103.428 40.2803 104.241 39.3502 105.158 39.3502C105.887 39.3502 106 39.9197 106 40.2519C106 40.4227 106 40.584 105.943 40.7739L105.14 44.5513L105.102 44.7127H106.104L106.7 41.9223C107.078 40.138 107.692 39.3407 108.666 39.3407C109.281 39.3407 109.621 39.6444 109.621 40.1854C109.621 40.3563 109.621 40.5461 109.565 40.7359L108.723 44.7127H109.725L110.548 40.8308C110.605 40.5935 110.642 40.3563 110.642 40.138C110.642 39.075 110.009 38.4675 108.893 38.4675V38.477Z"
            fill={color}
          />
          <path
            d="M116.013 41.8748L116.079 41.5995C116.202 41.0775 116.363 40.3562 116.363 39.8816C116.363 38.8186 115.21 38.4485 114.217 38.4485C112.77 38.4485 111.806 39.2078 111.636 40.4701L111.617 40.622H112.609L112.628 40.5081C112.78 39.5305 113.621 39.3217 114.302 39.3217C114.794 39.3217 115.37 39.4451 115.37 40.024C115.37 40.7358 115.162 40.9257 114.784 41.0206C114.557 41.0775 114.283 41.087 113.962 41.106C113.659 41.125 113.319 41.1345 112.95 41.1914C112.042 41.3433 110.822 41.7134 110.822 43.1181C110.822 44.2476 111.522 44.874 112.799 44.874C113.555 44.874 114.283 44.5418 114.737 44.0103C114.737 44.4848 115.011 44.7601 115.474 44.7601C115.711 44.7601 115.994 44.7126 116.07 44.7031L116.165 44.6842L116.344 43.83L116.136 43.8869C116.042 43.9154 115.994 43.9154 115.881 43.9154C115.645 43.9154 115.645 43.8584 115.645 43.8205C115.645 43.6211 115.692 43.4218 115.786 42.9947C115.843 42.729 115.919 42.3778 116.023 41.8843L116.013 41.8748ZM115.049 41.7419L114.907 42.4253C114.68 43.4408 113.98 44.0198 112.969 44.0198C112.534 44.0198 111.815 43.9059 111.815 43.1181C111.815 42.15 112.799 42.0077 113.886 41.9317H113.971C114.056 41.9127 114.151 41.9033 114.245 41.8938C114.5 41.8653 114.803 41.8368 115.039 41.7419H115.049Z"
            fill={color}
          />
          <path
            d="M118.264 43.8964C118.046 43.8964 118.027 43.6876 118.027 43.6307C118.027 43.4883 118.046 43.3934 118.093 43.1751L118.888 39.5305H120.069L120.24 38.6478H119.096L119.474 36.892H118.472L118.103 38.6478H117.063L116.893 39.502H117.923L117.148 43.1751C117.091 43.4219 117.063 43.6686 117.063 43.811C117.063 44.4564 117.469 44.7886 118.264 44.7886C118.5 44.7886 118.765 44.7601 119.001 44.7316H119.096L119.266 43.83L119.039 43.849C118.774 43.8774 118.5 43.9059 118.254 43.9059L118.264 43.8964Z"
            fill={color}
          />
          <path
            d="M122.405 36.3984H121.412L121.091 37.7936H122.084L122.405 36.3984Z"
            fill={color}
          />
          <path
            d="M119.663 44.5608L119.625 44.7221H120.618L121.885 38.8092L121.913 38.6478H120.92L119.663 44.5608Z"
            fill={color}
          />
          <path
            d="M125.345 38.4485C123.142 38.4485 121.988 40.3942 121.988 42.3114C121.988 43.9154 122.896 44.874 124.428 44.874C126.716 44.874 127.756 42.7954 127.756 40.8687C127.756 39.4356 126.81 38.4865 125.345 38.4485ZM126.763 41.0111C126.763 41.9412 126.394 42.8998 125.837 43.4598C125.468 43.8205 125.023 43.9913 124.541 43.9913C123.52 43.9913 122.953 43.3744 122.953 42.2544C122.953 41.0775 123.671 39.3312 125.203 39.3312C126.177 39.3312 126.763 39.9576 126.763 41.0111Z"
            fill={color}
          />
          <path
            d="M131.812 38.5055C131.122 38.5055 130.441 38.8282 129.95 39.3597L130.082 38.6478H129.146L127.851 44.7506H128.853L129.505 41.666C129.798 40.3183 130.678 39.3786 131.642 39.3786C131.964 39.3786 132.711 39.4641 132.711 40.2803C132.711 40.4227 132.682 40.6505 132.626 40.8308L131.784 44.7506H132.786L133.618 40.9162C133.675 40.6125 133.703 40.3752 133.703 40.1949C133.703 39.1129 133.013 38.496 131.812 38.496V38.5055Z"
            fill={color}
          />
          <path
            d="M186.132 35.4018C182.473 35.4018 179.438 34.1679 177.008 31.7002C174.644 29.1661 173.462 25.9866 173.462 22.1617C173.462 18.3368 174.672 15.0718 177.102 12.5757C179.561 10.0795 182.662 8.82666 186.415 8.82666C188.94 8.82666 191.2 9.40562 193.195 10.5635C195.199 11.7215 196.684 13.3065 197.667 15.3376L196.107 16.1918C195.284 14.4834 194.008 13.1261 192.278 12.1106C190.547 11.095 188.59 10.592 186.415 10.592C183.201 10.592 180.553 11.6645 178.473 13.819C176.393 15.9355 175.353 18.7259 175.353 22.1712C175.353 25.6164 176.365 28.3594 178.379 30.4759C180.393 32.5924 182.983 33.6554 186.132 33.6554C188.278 33.6554 190.235 33.1429 192.022 32.1179C193.8 31.0928 195.133 29.7166 196.022 28.0082L197.535 28.9573C196.495 30.9504 194.953 32.526 192.902 33.6839C190.85 34.8418 188.6 35.4113 186.141 35.4113L186.132 35.4018Z"
            fill={color}
          />
          <path
            d="M222.675 15.1383V9.44361H224.566V34.8323H222.675V28.9953C221.663 30.9884 220.226 32.564 218.354 33.7219C216.482 34.8798 214.374 35.4588 212.038 35.4588C208.474 35.4588 205.495 34.1775 203.103 31.6149C200.739 29.0238 199.558 25.8253 199.558 22.0288C199.558 18.2324 200.739 15.1668 203.103 12.6326C205.467 10.0985 208.445 8.83618 212.038 8.83618C214.402 8.83618 216.51 9.38667 218.373 10.4971C220.236 11.6076 221.663 13.1546 222.675 15.1478V15.1383ZM211.991 33.6934C215.139 33.6934 217.73 32.5545 219.744 30.2766C221.824 27.9987 222.864 25.2273 222.864 21.9719C222.864 18.7164 221.824 16.1538 219.744 13.9044C217.73 11.693 215.139 10.5825 211.991 10.5825C208.842 10.5825 206.412 11.674 204.427 13.857C202.441 16.0399 201.449 18.7734 201.449 22.0668C201.449 25.3602 202.441 28.1601 204.427 30.3715C206.412 32.5829 208.937 33.6934 211.991 33.6934Z"
            fill={color}
          />
          <path
            d="M232.603 34.8324H230.712V9.44367H232.461V13.7147C233.851 10.4213 236.309 8.7793 239.836 8.7793C240.999 8.7793 242.077 8.94065 243.051 9.25385L242.767 10.9623C241.916 10.6775 240.999 10.5352 240.025 10.5352C237.501 10.5352 235.629 11.4368 234.399 13.2401C233.199 15.0719 232.603 17.2738 232.603 19.8364V34.8324Z"
            fill={color}
          />
          <path
            d="M265.885 20.5482C265.885 20.8994 265.838 21.6397 265.743 22.7786H243.76C243.921 26.129 244.98 28.7865 246.956 30.7322C248.923 32.6779 251.57 33.6554 254.879 33.6554C256.61 33.6554 258.34 33.3707 260.061 32.8012C261.781 32.2318 263.209 31.4725 264.334 30.5234L265.327 31.947C264.126 32.9626 262.585 33.7883 260.694 34.4337C258.803 35.0791 256.846 35.4018 254.832 35.4018C250.577 35.4018 247.353 34.092 245.16 31.4915C242.966 28.8814 241.869 25.7399 241.869 22.0763C241.869 18.4127 243.004 15.0813 245.273 12.5852C247.571 10.089 250.596 8.83618 254.35 8.83618C257.687 8.83618 260.429 9.89919 262.576 12.0157C264.779 14.1037 265.885 16.9511 265.885 20.5577V20.5482ZM254.35 10.5825C251.39 10.5825 248.932 11.5317 246.975 13.4299C245.055 15.3281 243.997 17.8622 243.807 21.0228H263.994C263.994 17.8243 263.115 15.2996 261.347 13.4299C259.578 11.5317 257.252 10.5825 254.35 10.5825Z"
            fill={color}
          />
          <path
            d="M292.406 20.5482C292.406 20.8994 292.359 21.6397 292.265 22.7786H270.282C270.442 26.129 271.501 28.7865 273.477 30.7322C275.444 32.6779 278.092 33.6554 281.401 33.6554C283.131 33.6554 284.861 33.3707 286.582 32.8012C288.303 32.2318 289.731 31.4725 290.856 30.5234L291.849 31.947C290.648 32.9626 289.107 33.7883 287.216 34.4337C285.325 35.0791 283.367 35.4018 281.353 35.4018C277.099 35.4018 273.875 34.092 271.681 31.4915C269.487 28.8814 268.391 25.7399 268.391 22.0763C268.391 18.4127 269.525 15.0813 271.794 12.5852C274.092 10.089 277.118 8.83618 280.871 8.83618C284.209 8.83618 286.951 9.89919 289.097 12.0157C291.3 14.1037 292.406 16.9511 292.406 20.5577V20.5482ZM280.871 10.5825C277.912 10.5825 275.454 11.5317 273.496 13.4299C271.577 15.3281 270.518 17.8622 270.329 21.0228H290.515C290.515 17.8243 289.636 15.2996 287.868 13.4299C286.1 11.5317 283.774 10.5825 280.871 10.5825Z"
            fill={color}
          />
          <path
            d="M298.552 34.8324H296.661V9.44367H298.41V13.7147C299.8 10.4213 302.258 8.7793 305.785 8.7793C306.948 8.7793 308.026 8.94065 309 9.25385L308.716 10.9623C307.865 10.6775 306.948 10.5352 305.974 10.5352C303.45 10.5352 301.578 11.4368 300.348 13.2401C299.148 15.0719 298.552 17.2738 298.552 19.8364V34.8324Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_238_151">
            <rect width="309" height="47" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </Styled.Container>
  );
};

export default DHubLogo;
