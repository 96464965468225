const ThemeStyle = {
  colors: {
    black: "#151D1F",
    white: "#ffffff",
    gray: "#868686",
    lime: "#A7C20B",
    green: "#33712C",
    blue: "#02B0DA",
    navy: "#007395",
    formBgBlue: "#1E8BCF",
    darkBlue: "#10384F",
    teal: "#0FAFA5",
    purple: "#81516E",
    red: "#DD004B",
    turquoise: "#00CDC0",
    error: "#af0a0a",
  },
  fonts: {
    main: "Open Sans, Helvetica, sans-serif",
    second: "BebasNeue, sans-serif",
  },
  fontWeight: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  transition: "all .35s ease-in-out",
  maxWidth: "1600px",
  padding: {
    sm: "2rem",
    md: "4rem",
    lg: "7rem",
    xl: "9.4rem",
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px",
    xxxl: "1600px",
    ultra: "1920px",
  },
};

export default ThemeStyle;
