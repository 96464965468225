import React from "react";

export const FacebookIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_238_450)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C18.6274 0 24 5.37258 24 12C24 17.9897 19.6118 22.954 13.875 23.8542V15.4688H16.6711L17.2031 12H13.875V9.74906C13.875 8.80008 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C4.3882 22.954 0 17.9895 0 12C0 5.37258 5.37258 0 12 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_450">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
