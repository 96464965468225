import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { mediaDown } from "../../styles/Mixins";
import { ColorType } from "../../@types/ColorType";

type ButtonBaseProps = {
  color: ColorType;
};

type ButtonProps = ButtonBaseProps & {
  disabled: boolean;
};

const ButtonBase = css<ButtonBaseProps>`
  padding: 2rem 5rem;
  transition: ${ThemeStyle.transition};
  transition-duration: 0.5s;
  transition-property: filter, box-shadow;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  font-weight: ${ThemeStyle.fontWeight.bold};
  color: ${ThemeStyle.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25rem;
  border-radius: 54px;
  cursor: pointer;
  ${(props) =>
    props.color === "transparent" &&
    css`
      background-color: transparent;
      border: 2px solid ${ThemeStyle.colors.white};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 0 ${ThemeStyle.colors.white};
      }
    `}
  ${(props) =>
    props.color === "lime" &&
    css`
      background-color: ${ThemeStyle.colors.lime};
      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.lime};
      }
    `}
    ${(props) =>
    props.color === "red" &&
    css`
      background-color: ${ThemeStyle.colors.red};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.red};
      }
    `}
    ${(props) =>
    props.color === "purple" &&
    css`
      background-color: ${ThemeStyle.colors.purple};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.purple};
      }
    `}
    ${(props) =>
    props.color === "green" &&
    css`
      background-color: ${ThemeStyle.colors.green};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.green};
      }
    `}
    ${(props) =>
    props.color === "navy" &&
    css`
      background-color: ${ThemeStyle.colors.navy};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.navy};
      }
    `}
  ${(props) =>
    props.color === "blue" &&
    css`
      background-color: ${ThemeStyle.colors.blue};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.blue};
      }
    `}
    ${(props) =>
    props.color === "teal" &&
    css`
      background-color: ${ThemeStyle.colors.teal};

      &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 12px 3px ${ThemeStyle.colors.teal};
      }
    `}
    ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      min-width: unset;
      padding: 2rem 4rem;
    `
  )};
`;
export const ButtonLink = styled.a`
  ${ButtonBase};
`;

export const Button = styled.button<ButtonProps>`
  ${ButtonBase};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      filter: brightness(0.5);
    `}
`;
