import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from "styled-components";
import ThemeStyle from "./ThemeStyle";

export const mediaUp = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (min-width: ${media}) {
    ${children}
  }
`;

export const mediaDown = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (max-width: ${media}) {
    ${children}
  }
`;

export const AppContainer = css`
  display: flex;
  flex: 0 0 100%;
  margin: 0 auto;
  max-width: ${ThemeStyle.maxWidth};
  padding-left: ${ThemeStyle.padding.xl};
  padding-right: ${ThemeStyle.padding.xl};
  ${mediaDown(
    ThemeStyle.breakpoints.ultra,
    css`
      padding-left: ${ThemeStyle.padding.lg};
      padding-right: ${ThemeStyle.padding.lg};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.xl,
    css`
      padding-left: ${ThemeStyle.padding.md};
      padding-right: ${ThemeStyle.padding.md};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      padding-left: ${ThemeStyle.padding.sm};
      padding-right: ${ThemeStyle.padding.sm};
    `
  )}
`;

export const LinkHoverBefore = css`
  content: "";
  position: absolute;
  left: 50%;
  bottom: 4px;
  height: 2px;
  width: 0;
  transform: translateX(-50%);
  background-color: ${ThemeStyle.colors.lime};
  transition: ${ThemeStyle.transition};
  transition-property: width;
`;
